<!-- ======= Hero Section ======= -->
<section *ngIf="showHero" id="hero" #carouselContainer>
  <div class="hero-container">
    <carousel [isAnimated]="true" id="heroCarousel" class="">
      <slide *ngFor="let slide of heroSlides; let i = index;">
        <div class="carousel-background"><img [src]="'assets/img/slide/' + slide.src" [alt]="slide.alt"></div>
        <div class="carousel-container">
          <div class="carousel-content">
            <h2 class="">{{slide.title}}</h2>
            <p class="">{{slide.subtitle}}</p>
            <a *ngIf="slide.button" [href]="slide.button.link" class="btn-get-started scrollto">{{slide.button.text}}</a>
          </div>
        </div>
      </slide>
    </carousel>

  </div>
</section><!-- End Hero -->

<!-- ======= Header ======= -->
<header id="header" class="d-flex align-items-center">
  <div class="container d-flex align-items-center justify-content-between">

    <div class="logo">
      <h1 class="text-light"><a routerLink="home"><span>TLGYO.ORG</span></a></h1>
      <!-- Uncomment below to use image logo -->
      <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
    </div>

    <nav id="navbar" class="navbar" [ngClass]="showMobileNav ? 'navbar-mobile' : ''">
      <ul *ngIf="showHero">
        <li><a class="nav-link scrollto" href="#hero">Home</a></li>
        <li><a class="nav-link scrollto" href="#about">About Us</a></li>
        <li><a class="nav-link scrollto" href="#programs">Programs</a></li>
        <li><a class="nav-link scrollto" href="#events">Events</a></li>
        <li><a class="nav-link scrollto" href="#faq">FAQ</a></li>
        <li><a class="nav-link scrollto" href="#donors">Donors</a></li>
        <li><a class="nav-link scrollto" href="#team">Team</a></li>
        <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
        <!-- <li class="animated"><a class="animated pulse infinite nav-link scrollto special" routerLink="baked-sale" routerLinkActive="active">🍂Bake Sale🍂</a></li> -->
        <li><a class="nav-link getstarted scrollto" href="#donation">Donate</a></li>
      </ul>
      <ul *ngIf="!showHero">
        <li (click)="toggleMobileNav();"><a class="nav-link scrollto" routerLink="home" routerLinkActive="active">Home</a></li>
        <li (click)="toggleMobileNav();"><a class="nav-link scrollto special" routerLink="baked-sale" routerLinkActive="active">🍂Bake Sale🍂</a></li>
        <li><a class="nav-link getstarted" href="https://checkout.square.site/merchant/MLVHAV28WTS90/checkout/R5G6HXPJET7AQ7JJJ3FUUKHB" target="_blank">Donate</a></li>
      </ul>
      <i (click)="toggleMobileNav()" class="bi mobile-nav-toggle" [ngClass]="showMobileNav ? 'bi-x' : 'bi-list'"></i>
    </nav><!-- .navbar -->

  </div>
</header><!-- End Header -->

<main id="main">
  <router-outlet></router-outlet>
</main><!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-3 col-md-6 footer-info">
          <h3>{{contactInfo?.name}}</h3>
          <p>A 501(c)3 Organization<br>Tax Id: <b>{{contactInfo?.taxId}}</b></p>
          <p>
            {{contactInfo?.address?.full}}<br><br>
            <strong>Phone:</strong> +1 {{contactInfo?.phone}}<br>
            <strong>Email:</strong> {{contactInfo?.email}}<br>
          </p>
          <div class="social-links mt-3">
            <!-- <a href="" rget="_blank" class="twitter"><i class="bx bxl-twitter"></i></a> -->
            <a data-aos="fade-up" data-aos-delay="0" href="https://www.facebook.com/lookingglassyouthoutreach" rget="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a data-aos="fade-up" data-aos-delay="150" href="https://www.instagram.com/lookingglassyouthoutreach/" rget="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
            <!-- <a href="#" rget="_blank" class="google-plus"><i class="bx bxl-skype"></i></a> -->
            <a data-aos="fade-up" data-aos-delay="300" href="https://www.linkedin.com/in/tashia-cardec-a40b91b0" rget="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          </div>
        </div>

        <div class="col-lg-2 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#aboutus">About us</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#programs">Programs</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.termsandconditionsgenerator.com/live.php?token=IRYpUBlh8B5sRkJ2DrRiXyusCwXLikRm" target="_blank">Terms of service</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="https://www.generateprivacypolicy.com/live.php?token=7H84yyuiwEjzOfIeHeIs65fTYhoM1EJ1" target="_blank">Privacy policy</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Programs</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#programs">COPE</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#programs">Teen Sponsorship</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#programs">Teacher Sponsorship</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="https://checkout.square.site/merchant/MLVHAV28WTS90/checkout/R5G6HXPJET7AQ7JJJ3FUUKHB" target="_blank">Make A Donation</a></li>
            <!-- <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li> -->
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 footer-newsletter">
          <h4>Our Newsletter</h4>
          <p>Subscribe to get the TLGYO.ORG monthly newsletter. Receive organization updates, upcomging events, and other relavent information.</p>
          <tlgyo-sub-form></tlgyo-sub-form>
        </div>

      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright 2022 <strong><span>Through The Looking Glass Youth Outreach</span></strong> | All Rights Reserved
    </div>
    <div class="credits">

      This website was made with <i class="bi-heart-fill"></i> by <a href="https://suremarketingsolutions.com/"> Sure Marketing Solutions</a> a proud supporter of TLGYO.ORG
    </div>
  </div>
</footer><!-- End Footer -->

<button (click)="scrollToTop()" data-aos="fade-up" data-aos-offset="600" class="back-to-top d-flex align-items-center justify-content-center"><i
    class="bi bi-arrow-up-short"></i></button>


<!-- --------------- Modal Templates and Alerts --------------- -->

<!-- Newsletter Popup Modal -->
<div id="autoNewsletterModal" *ngIf="isNewsletterModalShown" [config]="{ show: isNewsletterModalShown, ignoreBackdropClick: false, class: 'modal-lg' }" (onHidden)="onNewsletterModalHidden()" bsModal #autoNewsletterModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-newsletter">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-newsletter" class="modal-title pull-left">TLGYO.ORG Newsletter</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideNewsletterModal()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <tlgyo-sub-form [helpMsg]="'We won\'t share your data'" [title]="'Subscribe to get updates on TLGYO.ORG news, upcoming events, fundraisers, volunteer oportunities, and much more. Direct to your inbox, once a month. 100% Spam-free.'"></tlgyo-sub-form>
      </div>
    </div>
  </div>
</div>
<tlgyo-alert [alertConfig]="alertConfig"></tlgyo-alert>

<div class="animated fast" [ngClass]="showLoader ? '':'slideOutDown'" id="loader-container">
  <div class="heart"></div>
</div>
