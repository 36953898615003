<div class="section" id="thank-you">
  <div class="p-5 mb-4 bg-light rounded-3">
    <div class="container-fluid py-5">
      <h1 class="display-5 fw-bold text-center">THANK YOU</h1>
      <div class="row justify-content-center">
        <p class="col-md-8 fs-4 text-center">Your order has been successfully submitted. You should see a confirmation in the inbox of the email address you provided. If you dont see it, email us at bakesale@tlgyo.org.</p>
      </div>
      <div class="row justify-content-center">
        <a routerLink="/home" class="btn btn-primary btn-lg col-2" type="button">RETURN HOME</a>
      </div>
    </div>
    </div>
</div>
