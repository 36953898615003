import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
  ModalOptions,
} from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from 'src/app/core/services/data.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'tlgyo-baked-sale',
  templateUrl: './baked-sale.component.html',
  styleUrls: ['./baked-sale.component.scss'],
})
export class BakedSaleComponent implements OnInit {
  @ViewChild('orderInfoModal', { static: false })
  orderInfoModal?: ModalDirective;
  buyerInfo?: any = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipcode: '',
    },
    paymentIsCard: true,
    paymentIsCash: false,
    pickupOrDelivery: 'pickup',
  };
  products: any = [];
  cart: any[] = [];
  alertConfig = {
    type: 'success',
    msg: '',
    dismissable: true,
    show: false,
    timeout: 5,
  };
  modalRef?: BsModalRef;
  isOrderInfoModalShown = false;

  constructor(
    private dataService: DataService,
    private modalService: BsModalService,
    private cookieService: CookieService,
    private router: Router,
    private meta: Meta,
    private title: Title,
    private analytics: AngularFireAnalytics
  ) {

    this.analytics.logEvent('BakeSale', {timestamp: Date.now()})

    this.title.setTitle('TLGYO.ORG | Virtual Bake Sale | Through The Looking Glass Youth Outreach')

    this.meta.addTags([
      { name: 'author', content: 'Sure Marketing Solutions: Jimi Flynn' },
      { property: 'og:title', content: 'TLGYO.ORG | Virtual Bake Sale' },
      { property: 'og:description', content: '3rd Annual Virtual Bake Sale is an online bake sale fundraiser for teens in need.' },
      { property: 'og:url', content: 'https://tlgyo.org/baked-sale' },
      {property: 'og:image', content: 'https://tlgyo.org/assets/img/events/bake-sale-cupcakes-post.png'},
    ])
  }

  addToCart(itemId: number) {
    // get item by itemId
    // destruct id, price, title and quantity from item
    // If quantity is 0, trigger alert and return
    // check if item exixts in cart
    // if yes, remove item from cart
    // push updated item to cart
    const item = this.products[itemId];
    const { price, title, quantity, description } = item;
    if (quantity < 1) {
      this.alertConfig.show = true;
      this.alertConfig.type = 'danger';
      this.alertConfig.msg = 'Oops! You must set a quantity first!';
      return;
    }
    if (this.cart.includes({ id: itemId })) {
      this.removeFromCart(itemId);
    }
    const itemTotal = price * quantity;
    this.cart.push({ id: itemId, item: title, price, description, quantity, itemTotal });
    this.products[itemId].added = true;
    this.alertConfig.show = true;
    this.alertConfig.type = 'success';
    this.alertConfig.timeout = 5;
    this.alertConfig.msg = 'Item added to cart!';
    console.log('added to cart');
  }

  removeFromCart(itemId: number) {
    const idx = this.cart.indexOf({ id: itemId });
    this.cart.splice(idx, 1);
    this.products[itemId].quantity = 0;
    this.products[itemId].added = false;
  }

  submitOrder(data: any, form: NgForm) {
    this.dataService
      .addToCollection('baked-sale-orders', data)
      .then((response: any) => {
        form.reset();
        this.alertConfig.show = true;
        this.alertConfig.msg = 'Way To Go! Your order has been sent!';
        this.router.navigateByUrl('/thank-you')
      })
      .catch((err) => {});
  }

  setQuantity(itemId: number, setTo: number = 1) {
    this.products[itemId].quantity = setTo;
  }

  increment(itemId: number) {
    let item = this.products[itemId];
    if (item.quantity > 100) return;
    item.quantity++;
  }

  decrement(itemId: number) {
    let item = this.products[itemId];
    if (item.quantity === 0) return;
    item.quantity--;
  }

  openModal(template: TemplateRef<any>, modalOpts?: ModalOptions) {
    this.modalRef = this.modalService.show(template, modalOpts);
  }

  hideModal(modalName: string) {
    this[modalName].hide();
  }

  hideOrderInfoModal(event: any) {
    this.isOrderInfoModalShown = false;
    this.cookieService.set('order_info', `readon-${Date.now() * 100}`);
  }

  onModalHidden(modalToggleName: string) {
    this[modalToggleName] = false;
  }

  ngOnInit(): void {
    this.dataService
      .getDBList('baked-sale')
      .valueChanges()
      .subscribe((items) => (this.products = items));
    if (!this.cookieService.check('order_info')) {
      setTimeout(() => {
        this.isOrderInfoModalShown = true;
      }, 5000);
    }
  }
}
