<section id="baked-sale">
  <!-- <div class="toolbar d-flex align-content-end pe-3">
    <button class="btn btn-primary cart-btn float-end ms-auto shadow-lg animated animate" [ngClass]="cart.length ? 'slideInDown' : 'slideOutUp'"><i class="bi bi-cart-fill"></i></button>
  </div> -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-6 mx-0 px-0">
        <h1 class="text-center">TLGYO.ORG 3rd Annual Bake Sale</h1>
        <hr>
      </div>
    </div>
      <div class="row align-items-md-stretch">
        <div class="col-md-6 text-center">
          <div
            class="h-100 p-5 text-black bkgd-gray border-0 rounded-3">
            <h2>Holiday Stockings Fundraiser</h2>
            <p>All proceeds will help fund the TLGYO.ORG Christmas Holiday Stockings for teens in need,
              TLGYO.ORG's most successfull fundraiser. Help teens <b>AND</b> get delicious baked goods. That's a win win!
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div
            class="h-100 p-5 bg-light border-0 rounded-3">
            <h2>Order Online</h2>
            <p>This year we have made it even easier for you to order, and help teens in need.
              Select your items below, then provide a few details and pay before or on delivery/pickup.
            </p>
          </div>
        </div>
      </div>

    <div class="row">

      <div *ngFor="let item of products; let i = index;" class="col-md-3">
          <div class="card mb-3">
            <div class="image-container">
              <div class="">
                <div class="d-flex justify-content-between align-items-center">

                <!-- <span class="discount">{{item.discount}}%</span> -->
                <!-- <span *ngIf="item.isWishlist" class="wishlist"><i class="bi bi-heart"></i></span> -->


                  </div>
              </div>

              <img [src]="'assets/img/' + item.img.src" [alt]="item.img.alt" [ngClass]="item.img.classes" class="img-fluid rounded thumbnail-image">

            </div>
            <div class="product-detail-container p-2">
                <div class="d-flex justify-content-between align-items-center">

                  <h5 class="item-name">{{item.title}}</h5>

                  <div class="d-flex flex-column mb-2">
                    <span class="new-price text-primary">${{item.price}}</span>
                    <!-- <small class="old-price text-right">$5.99</small> -->
                  </div>
                </div>

                <p>{{item.description}}</p>

                <div class="d-flex justify-content-between align-items-center pt-1">
                  <div class="col-6">
                    <div class="d-flex justify-content-start">
                      <div class="input-group w-auto justify-content-start align-items-center" [hidden]="item.added && item.quantity">
                         <input type="button" (click)="decrement(i)" value="-" class="button-minus border rounded-circle  icon-shape icon-sm mx-1 " data-field="quantity">
                         <input type="number" [(ngModel)]="item.quantity" #quantity="ngModel" step="1" max="10" value="1" name="quantity" class="quantity-field border-0 text-center w-25">
                         <input type="button" (click)="increment(i)" value="+" class="button-plus border rounded-circle icon-shape icon-sm " data-field="quantity">
                      </div>
                   </div>
                  </div>
                  <button class="btn btn-primary col" [hidden]="item.added && item.quantity" (click)="addToCart(i)" [tooltip]="!item.quantity ? 'You must set quantity first' : ''"
                  placement="bottom">ADD TO CART</button>
                  <button *ngIf="item.added && item.quantity" class="btn btn-danger bg-danger col" (click)="removeFromCart(i)">DELETE <i class="bi bi-trash-fill"></i></button>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div class="row align-content-between">
      <div class="col-md-6">
        <div class="wrapper rounded bg-white">
          <div class="h3">
            <h3>Buyer Information</h3>
            <p style="font-size: 60%;"><small><b style="color: red;">*</b> Indicates a required field</small></p>
          </div>
          <form #buyerInfoForm="ngForm" name="buyerInfoForm" class="form">
              <div class="row">
                  <div class="col-md-6 mt-md-0 mt-3">
                      <label><b>*</b>First Name</label>
                      <input [(ngModel)]="buyerInfo.firstname" #firstname="ngModel" type="text" name="firstname" id="firstname" class="form-control" required>
                      <small *ngIf="firstname.dirty && firstname.errors?.required" id="fnameHelp" class="form-text">First name is required</small>
                  </div>
                  <div class="col-md-6 mt-md-0 mt-3">
                      <label><b>*</b>Last Name</label>
                      <input [(ngModel)]="buyerInfo.lastname" #lastname="ngModel" type="text" id="lastname" name="lastname" class="form-control" required>
                      <small *ngIf="lastname.dirty && lastname.errors?.required" id="lnameHelp" class="form-text">Last name is required</small>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6 mt-md-0 mt-3">
                      <label><b>*</b>Email</label>
                      <input [(ngModel)]="buyerInfo.email" #email="ngModel" type="email" id="email" name="email" class="form-control" required>
                      <small *ngIf="email.dirty && email.errors?.required" id="emailHelp" class="form-text">Email is required</small>
                  </div>
                  <div class="col-md-6 mt-md-0 mt-3">
                      <label><b>*</b>Phone Number</label>
                      <input [(ngModel)]="buyerInfo.phone" #phone="ngModel" type="tel" id="phone" name="phone" class="form-control" required>
                      <small *ngIf="phone.dirty && phone.errors?.required" id="phoneHelp" class="form-text">Phone number is required</small>
                  </div>
              </div>
              <div class="row">
                <div class="col-12 mt-md-0 mt-3">
                  <label><b>*</b>Address line 1</label>
                  <input [(ngModel)]="buyerInfo.address.line1" #addressOne="ngModel" type="text" class="form-control" name="addressLine1" id="addressLine1" required>
                  <small *ngIf="addressOne.dirty && addressOne.errors?.required" id="addressHelp" class="form-text">Address is required</small>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-md-0 mt-3">
                  <label>Address line 2 (Optional)</label>
                  <input [(ngModel)]="buyerInfo.address.line2" #addressTwo="ngModel" type="text" class="form-control" name="addressLine2" id="addressLine2">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mt-md-0 mt-3">
                    <label><b>*</b>City</label>
                    <input [(ngModel)]="buyerInfo.address.city" #city="ngModel" type="text" id="city" name="city" class="form-control" required>
                    <small *ngIf="city.dirty && city.errors?.required" id="cityHelp" class="form-text">City is required</small>
                </div>
                <div class="col-md-6 mt-md-0 mt-3">
                    <label><b>*</b>State</label>
                    <input [(ngModel)]="buyerInfo.address.state" #state="ngModel" type="text" id="state" name="state" class="form-control" required>
                    <small *ngIf="state.dirty && state.errors?.required" id="stateHelp" class="form-text">State is required</small>
                </div>
            </div>
            <div class="row">
              <div class="col-6 mt-md-0 mt-3">
                <label><b>*</b>Zipcode</label>
                <input [(ngModel)]="buyerInfo.address.zipcode" #zipcode="ngModel" type="text" class="form-control" name="addressZip" id="addressZip" required>
                <small *ngIf="zipcode.dirty && zipcode.errors?.required" id="zipcodeHelp" class="form-text">Zipcode is required</small>
              </div>
            </div>
              <div class="row">
                <div class="col-md-6 mt-md-0 mt-3">
                    <label>Payment Method</label>
                    <div class="d-flex align-items-center mt-2">
                      <div class="form-check me-3">
                        <input [disabled]="cash.value === true" class="form-check-input" type="checkbox" value="" [(ngModel)]="buyerInfo.paymentIsCard" #card="ngModel" id="card" name="card" checked>
                        <label class="form-check-label" for="card">
                          Credit/Debit
                        </label>
                      </div>
                      <div class="form-check">
                        <input [disabled]="card.value === true" class="form-check-input" type="checkbox" [(ngModel)]="buyerInfo.paymentIsCash" #cash="ngModel" id="cash" name="cash">
                        <label class="form-check-label" for="cash">
                          Cash
                        </label>
                      </div>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-12 my-md-2 my-3">
                  <label>Pickup/Delivery</label>
                  <select class="form-control" [(ngModel)]="buyerInfo.deliveryOrPickup" #delivery="ngModel" name="pickupOrDelivery" id="pickupOrDelivery" required>
                      <option value="" selected hidden>Choose Option</option>
                      <option value="Pickup">Pickup</option>
                      <option value="Delivery">Delivery</option>
                  </select>
                  <small *ngIf="delivery.dirty && delivery.errors?.required" id="pickupHelp" class="form-text">Pickup or Delivery is required</small>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p><b>NOTE: </b>By clicking 'SUBMIT ORDER', you agree to pay for your selected items. If you find any errors in your confirmation email, please email corrections to bakesale@tlgyo.org <b>within 3 days of receiving order confirmation.</b></p>
              </div>
            </div>
            <div class="row align-items-end">
              <button class="btn btn-primary mt-3" [disabled]="buyerInfoForm.invalid || buyerInfoForm.pristine" (click)="submitOrder({buyerInfo, cart}, buyerInfoForm)">SUBMIT ORDER</button>
            </div>
          </form>

      </div>
      </div>
      <div class="col-md-6">
        <div class="text-wrapper">
          <div class="h3">
            <h3>What comes next?</h3>
          </div>
          <div class="container">
            <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">1.</b> Review the items you have added to your cart. If you want to remove an item, simply click the delete button.</p>
            <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">2.</b> Complete the buyer information form. Be sure to fill out every field. Missing information can delay, or even cancel your order.</p>
            <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">3.</b> Review your buyer information, then click on the 'SUBMIT' button. This will finalize your order and send a copy to the email you provided in the buyer information form.</p>
            <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">4.</b> After TLGYO.ORG recieves your order, an invoice will be generated if you are paying with a credit or debit card. You can pay anytime up until delivery or pickup of your items.</p>
            <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">5.</b> Review the order confirmation email. If you find any errors, send corrections to bakesale@tlgyo.org within 3 days of receiving your order confirmation email. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Bake Sale Alert Component -->
<tlgyo-alert [alertConfig]="alertConfig"></tlgyo-alert>

<!-- Bake Sale Info Model -->
<div id="orderInfoModal" *ngIf="isOrderInfoModalShown" [config]="{ show: isOrderInfoModalShown, ignoreBackdropClick: true, class: 'modal-lg' }" (onHidden)="hideOrderInfoModal($event)" bsModal #orderInfoModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-newsletter">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 id="dialog-auto-newsletter" class="modal-title pull-left"><i class="bi bi-info text-primary"></i> TLGYO.ORG Baked Sale Order Info</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal('orderInfoModal')">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">1.</b> Review the items you have added to your cart. If you want to remove an item, simply click the delete button.</p>
          <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">2.</b> Complete the buyer information form at the bottom of this page. Be sure to fill out every field. Missing information can delay, or even cancel your order.</p>
          <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">3.</b> Review your buyer information, then click on the 'SUBMIT' button. This will finalize your order and send a copy to the email you provided in the buyer information form.</p>
          <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">4.</b> After TLGYO.ORG recieves your order, an invoice will be generated if you are paying with a credit or debit card. You can pay anytime up until delivery or pickup of your items.</p>
          <p class="mb-5"><b class="text-black-50" [style.font-size]="'2rem'">5.</b> Review the order confirmation email. If you find any errors, send corrections to bakesale@tlgyo.org within 3 days of receiving your order confirmation email. </p>
        </div>
        <p><b>Click 'I UNDERSTAND' to continue selecting bake sale items.</b></p>
      </div>
      <div class="modal-footer align-items-end">
        <button class="btn btn-primary" (click)="hideModal('orderInfoModal')">I UNDERSTAND</button>
      </div>
    </div>
  </div>
</div>
