import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BakedSaleComponent } from './pages/baked-sale/baked-sale.component';
import { HomeComponent } from './pages/home/home.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  // {
  //   path: 'baked-sale',
  //   component: BakedSaleComponent
  // },
  // {
  //   path: 'thank-you',
  //   component: ThankYouComponent
  // },
  {
    redirectTo: '',
    path: 'home',
    pathMatch: 'full'
  },
  {
    redirectTo: '**',
    path: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
